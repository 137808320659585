import {initApolloAndGetEmptyProps} from '@/utils/server';

import EmptyLayout from '@/components/layout/empty/EmptyLayout';
import PageInitiator from '@/components/PageInitiator';
import Splash from '@/components/Splash/Splash';

import type {GetServerSidePropsContext} from 'next';
import type {ReactElement} from 'react';

export async function getServerSideProps(context: GetServerSidePropsContext) {
  return await initApolloAndGetEmptyProps(context);
}

export default function SplashScreen() {
  return <Splash />;
}

SplashScreen.getLayout = function getLayout(page: ReactElement) {
  return (
    <PageInitiator {...page.props}>
      <EmptyLayout>{page}</EmptyLayout>
    </PageInitiator>
  );
};
