import SEOHead from '@/components/heads/SEOHead';

import s from './EmptyLayout.module.scss';

interface EmptyLayoutProps {
  pageTitle?: string;
  children: React.ReactNode;
}

export default function EmptyLayout({pageTitle = 'Ka. Web App', children}: EmptyLayoutProps) {
  return (
    <>
      <SEOHead pageTitle={pageTitle} />
      <div className={s.container}>{children}</div>
    </>
  );
}
